import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Typography,
    AutoComplete,
    Table,
    Button,
    Dropdown,
    Menu,
    Row,
    Col,
    Spin,
    Space,
    Tooltip,
    Select,
    notification
} from 'antd'
import { withRouter } from 'react-router'
import _ from 'lodash'
import {
    SwapOutlined,
    MoreOutlined,
    CloseOutlined,
    ExportOutlined,
    SettingOutlined,
    PushpinOutlined,
} from '@ant-design/icons'
import {
    setCompareSearchAndRefresh,
    setCompareFilters,
    removeCompareEntity,
    clearCompareEntities,
    setCompareEntities,
    compareSearch,
    addAndLoadCompareEntity,
    exportCompareEntity,
    setCompareFiltersAndRefresh,
    setActivePortfolio
} from '../../features/entity/entityActions'
import { MISSING_METADATA_STR } from '../../shared/constants'
import SearchOptionsModal from './SearchOptionsModal'
import PortfolioModal from './PortfolioModal'
import PortfolioConfirmModal from './ConfirmModal'
import './compare.scss'
import GAService from '../../GAService'
import { canExport } from '../../shared/Authorization'
import apiService from '../../features/apiService'
import { ERROR_COLOR } from '../../shared/constants'
import LogRocket from 'logrocket';

class Compare extends Component {
    constructor(props) {
        super(props);

        this.searchInput = null;

        /*if (!props.entities.compareFilters) {
            props.setCompareFiltersAndRefresh({});
        } */

        this.toggleMoreSearch = this.toggleMoreSearch.bind(this)
        this.batchSearch = this.batchSearch.bind(this)
        this.setLoading = this.setLoading.bind(this)
        this.onYearSelected = this.onYearSelected.bind(this)
        this.handlePortfolioChange = this.handlePortfolioChange.bind(this);
        this.closePortfolioModal = this.closePortfolioModal.bind(this);
        this.createPortfolio = this.createPortfolio.bind(this);
        this.deletePortfolio = this.deletePortfolio.bind(this);
        this.closePortfolioConfirmModal = this.closePortfolioConfirmModal.bind(this);
    }

    state = {
        searchValue: '',
        yearSelected: '0',
        actionEntity: null,
        highlightEntity: [],
        dragItemIndex: -1,
        showSearchOptionDialog: false,
        showPortfolioDialog: false,
        showPortfolioConfirmDialog: false,
        loading: false,
        savingPortfolio: false,
        activePortfolioValue: this.props.entities.activePortfolio ?? null,
        portfolios: [{ value: 0, label: 'Create a New Portfolio' }],
    }

    componentDidMount() {
        apiService.getPortfolios(
            this.props.token
        ).then((data) => {
            console.log('portfolio data: ', data);
            if (data.data?.result) {
                const portfolios = [{ value: 0, label: 'Create a New Portfolio' }];
                data.data.result.forEach(p => {
                    portfolios.push({ value: p.id, label: p.name });
                });
                this.setState({ portfolios: portfolios });
            }
        })
    }

    getSearchBar() {
        const {
            addAndLoadCompareEntity,
            setCompareSearchAndRefresh,
            entities,
        } = this.props
        const { token } = this.props
        const { Option } = AutoComplete

        const { compareCandidates, compareSearch, all } = entities
        const { items } = compareCandidates ? compareCandidates : all

        if (items) {
            return (
                <>

                    <AutoComplete
                        style={{
                            width: '99%',
                        }}
                        value={compareSearch}
                        onChange={(searchValue, obj) => {
                            let schoolName
                            if (obj && obj.key) {
                                schoolName = obj.key.split('-')[0]
                            }
                            setCompareSearchAndRefresh(
                                schoolName || searchValue
                            )
                        }}
                        onSelect={(val) => {
                            const target = _.filter(
                                items,
                                (school) => school.id === val
                            )
                            GAService.compare("compare", target[0].charter_type);
                            addAndLoadCompareEntity(token, target[0])

                            if (!!this.searchInput) {
                                this.searchInput.blur();
                            }
                        }}
                        onFocus={(ev) => {
                            // highlight all text so user can easily input new search terms
                            this.searchInput = ev.target;
                            ev.target.select();
                        }}
                        placeholder={'Enter school name'}
                        filterOption={(inputValue, option) => {
                            return true;
                            /*(
                                option.children
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                            )*/
                        }}>
                        {items.map((school) => (
                            <Option
                                key={`${school.name}-${school.id}`}
                                value={school.id}>
                                {`${school.name} (${school.charter_type}, ${school.state})`}
                            </Option>
                        ))}
                    </AutoComplete>

                </>
            )
        }
    }

    getEntityId = (e) => {
        if (e) {
            return e.school_id ? e.school_id : (e.cmo_id ? e.cmo_id : e.obligated_group_id);
        }

        return null;
    }

    handleMenuAction(event) {
        const { removeCompareEntity } = this.props
        const { actionEntity, highlightEntity } = this.state

        if (event.key === 'remove') {
            removeCompareEntity(actionEntity.id)
            _.remove(highlightEntity, (obj) => this.getEntityId(obj) === this.getEntityId(actionEntity))
        } else if (event.key === 'unhighlight') {
            _.remove(highlightEntity, (obj) => this.getEntityId(obj) === this.getEntityId(actionEntity))
        } else {
            highlightEntity.push(actionEntity)
        }
        this.setState({ highlightEntity })
    }

    handleClearButton(event) {
        const { clearCompareEntities, setCompareFilters, setCompareSearchAndRefresh } = this.props
        this.setState({ ...this.state, searchValue: '', activePortfolioValue: null })
        setCompareSearchAndRefresh('');
        setCompareFilters(null);
        clearCompareEntities()
    }

    async handleExportButton(event, compareEntities) {
        const { token } = this.props
        GAService.exportReport('compare', 'report');

        let excel = await exportCompareEntity(token, compareEntities)
        if (excel) {
            // convert base64 file to byte array and generate object URL
            const byteCharacters = atob(excel)
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            let objectUrl = URL.createObjectURL(
                new Blob([byteArray], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                })
            )
            window.location.href = objectUrl
        }
    }

    getStateFilters() {
        return this.props.entities &&
            this.props.entities.all &&
            this.props.entities.all.active_states
            ? this.props.entities.all.active_states.map((s) => {
                return { text: s.name, value: s.abbreviation }
            })
            : []
    }

    toggleMoreSearch(event) {
        if (this.state.showSearchOptionDialog) {
            this.setState({ showSearchOptionDialog: false });
        }
        else {
            this.setState({ showSearchOptionDialog: true });
        }
    }

    setLoading(value) {
        this.setState({ loading: value });
    }

    onYearSelected(event) {
        let compareFilters = this.props.entities.compareFilters ? this.props.entities.compareFilters : {};
        if (event.target.value === '0') {
            // remove year filter
            delete compareFilters['year'];
            setCompareFilters(compareFilters);
        }
        else {
            compareFilters.year = event.target.value;
            setCompareFilters(compareFilters);
        }
        this.batchSearch(compareFilters, this.setLoading);
    }

    handleTableChange = (pagination, filters, sorter) => {
        let compareFilters = this.props.entities.compareFilters ? this.props.entities.compareFilters : [];
        var updatedFilters = {
            ...compareFilters,
            ...filters,
            //limit: pagination.pageSize,
            currentPage: pagination.current,
            offset: (pagination.current - 1) * pagination.pageSize,
        }
        if (sorter && sorter.field && sorter.order) {
            const sortName = `sort_${sorter.field}`
            updatedFilters = {
                ...updatedFilters,
                [sortName]: sorter.order,
            }
        }

        this.props.setCompareFiltersAndRefresh(updatedFilters);
    }

    batchSearch(filters) {
        this.props.compareSearch(filters, this.setLoading);
    }

    async handlePortfolioChange(value) {
        const { setActivePortfolio } = this.props
        console.log('selected portfolio value: ', value);

        if (value === 0) {
            //this.handleClearButton(null);
            this.setState({ activePortfolioValue: value, showPortfolioDialog: true });
            setActivePortfolio(value);
        }
        else {
            this.handleClearButton(null);
            this.setState({ activePortfolioValue: value, loading: true });
            setActivePortfolio(value);
            const result = await apiService.getPortfolio(this.props.token, value);

            if (result.status === 200) {
                if (result.data.result) {
                    const {
                        setCompareEntities
                    } = this.props;
                    const loadPromises = [];
                    result.data.result.forEach((r) => {
                        console.log("Handle entity: ", r);
                        if (r.school_name || r.cmo_name || r.og_name) {
                            const token = this.props.token;
                            loadPromises.push(apiService.getCompareEntityData(
                                token,
                                {
                                    school_id: r.school_id,
                                    cmo_id: r.cmo_id,
                                    obligated_group_id: r.obligated_group_id
                                }
                            ));
                        }
                    });

                    const loadedResults = await Promise.allSettled(loadPromises);
                    const entities = { total: 0, items: [] };
                    loadedResults.forEach(r => {
                        if (r.status === 'fulfilled') {
                            if (r.value.status === 200) {
                                entities.items.push(r.value.data);
                                entities.total++;
                            }
                        }
                    });
                    setCompareEntities(entities);

                }
            }
            this.setState({ loading: false });
        }
    }

    closePortfolioModal() {
        this.setState({ showPortfolioDialog: false, activePortfolioValue: null });
    }

    createPortfolio(name) {
        if (!!name) {
            const { setActivePortfolio } = this.props;
            apiService.upsertPortfolio(this.props.token, this.state.activePortfolioValue, name, this.props.entities?.compareEntities?.items).then((data) => {
                this.setState({ portfolios: [{ label: "Create a New Portfolio", value: 0 }, { label: name, value: data.data.id }, ...this.state.portfolios.slice(1)] });
                setActivePortfolio(data.data.id);
                const that = this;
                setTimeout(function () {
                    that.setState({ activePortfolioValue: data.data.id });
                }, 200);
            });
        }
        this.setState({ showPortfolioDialog: false });
    }

    closePortfolioConfirmModal() {
        this.setState({ showPortfolioConfirmDialog: false });
    }

    async handleDeletePortfolio() {
        if (this.state.activePortfolioValue > 0) {
            this.setState({ showPortfolioConfirmDialog: true });
        }
    }

    deletePortfolio() {
        if (this.state.activePortfolioValue > 0) {
            const { setActivePortfolio } = this.props;
            const that = this;
            apiService.deletePortfolio(this.props.token, this.state.activePortfolioValue).then((data) => {
                const pindex = that.state.portfolios.findIndex(p => p.value === that.state.activePortfolioValue);
                that.state.portfolios.splice(pindex, 1);
                that.setState({ portfolios: [...that.state.portfolios] });
                setActivePortfolio(null);
                this.handleClearButton(null);
                const that1 = this;
                setTimeout(function () {
                    that1.setState({ activePortfolioValue: null });
                }, 200);
            });
        }
        this.setState({ showPortfolioConfirmDialog: false });
    }

    savePortfolio() {
        if (this.props.entities?.compareEntities?.items && this.state.activePortfolioValue > 0) {
            this.setState({ savingPortfolio: true });
            const items = this.props.entities.compareEntities.items.map(t => {
                return { school_id: t.school_id, cmo_id: t.cmo_id, obligated_group_id: t.obligated_group_id }
            });
            const portfolio = this.state.portfolios.find(p => p.value === this.state.activePortfolioValue);

            apiService.upsertPortfolio(
                this.props.token,
                this.state.activePortfolioValue,
                portfolio.label,
                items).then((data) => {
                    this.setState({ savingPortfolio: false });
                }).catch((err) => {
                    notification.error({
                        duration: 0,
                        placement: 'topRight',
                        message: 'Login Error',
                        description: 'Failed to save the portfolio. Please try again later.',
                        style: { backgroundColor: ERROR_COLOR },
                    });
                    this.setState({ savingPortfolio: false });
                });
        }
    }

    render() {
        const { Title } = Typography
        const { entities, user } = this.props
        const { compareEntities } = entities
        const { highlightEntity } = this.state
        const { setCompareEntities } = this.props
        const { compareFilters } = entities

        //console.log('compare filters: ', compareFilters);
        
        if (user?.email) {
            LogRocket.identify(user.email);
        }

        const menu = (
            <Menu onClick={(event) => this.handleMenuAction(event)}>
                <Menu.Item key='highlight'>Highlight</Menu.Item>
                <Menu.Item key='unhighlight'>Unhighlight</Menu.Item>
                <Menu.Item key='remove'>Remove</Menu.Item>
            </Menu>
        )
        const defaultColumnRender = (text, record) => {
            return text ? text : MISSING_METADATA_STR
        }

        const defaultLocaleStrRender = (text, record) => {
            return text ? text.toLocaleString() : MISSING_METADATA_STR
        }

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 150,
                fixed: 'left',
            },
            {
                title: 'Entity Type',
                dataIndex: 'charter_type',
                key: 'entity_type',
                width: 120,
                fixed: 'center',
                filters: [
                    {
                        text: 'CMO',
                        value: 'CMO',
                    },
                    {
                        text: 'Obligated Group',
                        value: 'Obligated Group',
                    },
                    {
                        text: 'Freestanding',
                        value: 'Freestanding',
                    },
                    {
                        text: 'CMO Member',
                        value: 'CMO Member',
                    },
                    {
                        text: 'Obligated Group Member',
                        value: 'Obligated Group Member',
                    },
                    {
                        text: 'N/A',
                        value: 'N/A',
                    },
                ],
                defaultFilteredValue: compareFilters ? compareFilters.entity_type : '',
                filteredValue: compareFilters ? compareFilters.entity_type : '',
            },
            {
                title: 'Number of Schools',
                dataIndex: 'num_schools',
                key: 'num_schools',
                width: 120,
                render: (text, record) => {
                    return text ? text.toLocaleString() : 1
                },
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                width: 80,
                fixed: 'center',
            },
            {
                title: 'County',
                dataIndex: 'county',
                key: 'county',
                width: 90,
                fixed: 'center',
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                width: 100,
                /*filters: this.getStateFilters(),
                defaultFilteredValue: compareFilters ? compareFilters.state : null,
                filteredValue: compareFilters ? compareFilters.state : null, */
                render: (text, record) => {
                    return text ? text.toLocaleString() : 1
                },
            },
            {
                title: 'Year Opened/Founded',
                dataIndex: 'year_opened',
                key: 'year_opened',
                width: 150,
                fixed: 'center',

                render: (text, record) => {
                    return text ? text : MISSING_METADATA_STR
                },
            },
            {
                title: 'Grade Span',
                dataIndex: 'grade_span',
                key: 'grade_span',
                width: 100,
                render: (text, record) => {
                    return text ? text : MISSING_METADATA_STR
                },
            },
            {
                title: 'Enrollment',
                dataIndex: 'enrollment',
                key: 'enrollment',
                width: 120,
                render: defaultLocaleStrRender,
            },
            {
                title: 'Enrollment Drop (%)',
                dataIndex: 'enrollment_drop_pct',
                key: 'enrollment_drop_pct',
                width: 120,
                render: defaultLocaleStrRender,
            },
            {
                title: 'Eco Disadvantaged %',
                dataIndex: 'disadvantaged_percentage',
                key: 'disadvantaged_percentage',
                width: 120,
                render: (text, record) => {
                    return (text != null)
                        ? (record.ed_year ? `${text.toFixed(1)} (${(record.ed_year)})` : `${text}`)
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'Avg Attendance %',
                dataIndex: 'attendance_pct',
                key: 'attendance_pct',
                width: 120,
                render: (text, record) => {
                    return (text != null)
                        ? (record.attendance_year ? `${text.toFixed(1)} (${(record.attendance_year)})` : `${text}`)
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'Graduation %',
                dataIndex: 'graduation_percent',
                key: 'graduation_percent',
                width: 120,
                render: (text, record) => {
                    return (text != null)
                        ? (record.gr_year ? `${text.toFixed(1)} (${(record.gr_year)})` : `${text}`)
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'Dropout %',
                dataIndex: 'dropout_percent',
                key: 'dropout_percent',
                width: 120,
                render: (text, record) => {
                    return (text != null)
                        ? (record.dr_year ? `${text.toFixed(1)} (${(record.dr_year)})` : `${text}`)
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'Academic Grade',
                dataIndex: 'spg_grade',
                key: 'spg_grade',
                width: 100,
                render: (text, record) => {
                    return text
                        ? (record.spg_year ? `${text} (${(record.spg_year)})` : `${text}`)
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'Academic Growth Score',
                dataIndex: 'eg_score',
                key: 'eg_score',
                width: 100,
                render: (text, record) => {
                    return text
                        ? (record.eg_year ? `${text} (${(record.eg_year)})` : `${text}`)
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'Academic Growth Status',
                dataIndex: 'eg_status',
                key: 'eg_status',
                width: 100,
                render: (text, record) => {
                    return text
                        ? (record.egst_year ? `${text} (${(record.egst_year)})` : `${text}`)
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'Beginning Teacher %',
                dataIndex: 'beginning_teachers',
                key: 'beginning_teachers',
                width: 120,
                render: (text, record) => {
                    return (text != null)
                        ? (record.tb_year ? `${text.toFixed(1)} (${(record.tb_year)})` : `${text}`)
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'Fiscal Year End',
                dataIndex: 'fiscal_end',
                key: 'fiscal_end',
                width: 100,
                render: (text, record) => {
                    return text
                        ? (record.year ? `${text}, ${(record.year)}` : `${text}`)
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'Audited Entity',
                dataIndex: 'audit_source',
                key: 'audit_source',
                width: 100
            },
            {
                title: 'Accounting Type',
                dataIndex: 'accounting_type',
                key: 'accounting_type',
                width: 120,
                render: defaultColumnRender,
            },
            {
                title: 'Total Revenue',
                dataIndex: 'total_revenue',
                key: 'total_revenue',
                width: 100,
                render: defaultLocaleStrRender,
            },
            {
                title: 'Total Expense',
                dataIndex: 'total_expense',
                key: 'total_expense',
                width: 100,
                render: defaultLocaleStrRender,
            },
            {
                title: 'Net Income',
                dataIndex: 'net_income',
                key: 'net_income',
                width: 100,
                render: defaultLocaleStrRender,
            },
            {
                title: 'EBIDA',
                dataIndex: 'ebida',
                key: 'ebida',
                width: 100,
                render: defaultLocaleStrRender,
            },
            {
                title: 'Net Income Margin (%)',
                dataIndex: 'net_income_margin_percentage',
                key: 'net_income_margin_percentage',
                width: 100,
                render: (text, record) => {
                    return text
                        ? `${(text * 100).toFixed(1)}%`
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'EBIDA Margin (%)',
                dataIndex: 'ebida_margin',
                key: 'ebida_margin',
                width: 100,
                render: (text, record) => {
                    return text
                        ? `${(text * 100).toFixed(1)}%`
                        : MISSING_METADATA_STR
                },
            },
            {
                title: 'Days Cash Covenant',
                dataIndex: 'days_cash_covenant',
                key: 'days_cash_covenant',
                width: 100,
                render: (text, record) => {
                    return text ? text.toFixed(1) : MISSING_METADATA_STR
                },
            },
            {
                title: 'Days Cash on Hand',
                dataIndex: 'days_cash_on_hand',
                key: 'days_cash_on_hand',
                width: 100,
                render: (text, record) => {
                    return text ? text.toFixed(1) : MISSING_METADATA_STR
                },
            },
            {
                title: 'DSC Covenant',
                dataIndex: 'dsc_covenant',
                keys: 'dsc_covenant',
                width: 100,
                render: (text, record) => {
                    return text ? `${text.toFixed(2)}x` : MISSING_METADATA_STR
                },
            },
            {
                title: 'Annual Debt Service',
                dataIndex: 'annual_debt_service',
                keys: 'annual_debt_service',
                width: 100,
                render: defaultLocaleStrRender,
            },
            {
                title: 'Annual Debt Service Coverage (x)',
                dataIndex: 'annual_debt_service_coverage',
                keys: 'annual_debt_service_coverage',
                width: 100,
                render: (text, record) => {
                    return text ? `${text.toFixed(2)}x` : MISSING_METADATA_STR
                },
            },
            {
                title: 'Cash + Inv. + Bd. Desig.',
                dataIndex: 'unrestricted_cash_and_investments',
                keys: 'unrestricted_cash_and_investments',
                width: 100,
                render: defaultLocaleStrRender,
            },
            {
                title: 'Total Debt',
                dataIndex: 'total_debt',
                keys: 'toal_debt',
                width: 100,
                render: defaultLocaleStrRender,
            },
            {
                title: 'Cash To Debt (%)',
                dataIndex: 'unrestricted_cash_investments_to_debt',
                keys: 'unrestricted_cash_investments_to_debt',
                width: 100,
                render: (text, record) => {
                    return text ? `${text.toFixed(1)}%` : MISSING_METADATA_STR
                },
            },
            {
                title: 'Options',
                key: 'options',
                width: 100,
                fixed: 'right',
                render: (text, record) => (
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Button>
                            <MoreOutlined />
                        </Button>
                    </Dropdown>
                ),
                onCell: (record, recordIndex) => {
                    return {
                        onClick: (event) => {
                            this.setState({ actionEntity: record })
                        },
                    }
                },
            },
        ]

        return (
            <>
                <Row style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Col xs={5}>
                        <Title level={2} style={{ marginTop: -30 }}> {<SwapOutlined />} Compare</Title>
                    </Col>

                </Row>
                <Row>
                    <Col xs={3}>
                        <div title="Create, save, and manage custom portfolios" style={{ fontWeight: 'bold', paddingTop: '5px', width: '98%', textAlign: 'center' }}>Custom Portfolio</div>
                    </Col>
                    <Col title="Create, save, and manage custom portfolios" xs={6} id='portfolio_select' >
                        <Select style={{
                            width: '100%',
                        }}
                            value={this.state.activePortfolioValue}
                            placeholder="Create a New Portfolio"
                            options={this.state.portfolios}
                            onChange={this.handlePortfolioChange}
                        />
                    </Col>
                    <Col xs={1}>
                        <Button
                            className='compare-export-button export-button'
                            style={{ marginTop: 0, marginLeft: 5 }}
                            onClick={(e) => { this.savePortfolio() }}>
                            Save
                        </Button>
                    </Col>
                    <Col xs={1}>
                        <Button
                            className='compare-button'
                            style={{ marginTop: 0, marginLeft: 25 }}
                            onClick={(e) => { this.handleDeletePortfolio() }}>
                            Delete
                        </Button>
                    </Col>
                    {this.state.savingPortfolio &&
                        <Col xs={2} style={{ marginLeft: '10px', marginTop: '5px' }}>
                            <Spin size="medium" />
                        </Col>}
                </Row>
                <Row
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '10px'
                    }}>
                    <Col xs={24} sm={16}>{this.getSearchBar()}</Col>
                    {/*<Col sm={2}>
                        <select onChange={this.onYearSelected} value={this.state.selectedYear}>
                            <option value="0">All Years</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2021">2020</option>
                        </select>
                </Col> */}

                    <Col sm={2}>
                        <Button
                            className='compare-button'
                            style={{ marginTop: 0 }}
                            icon={<CloseOutlined />}
                            onClick={(e) => this.handleClearButton(e)}>
                            Clear
                        </Button>
                    </Col>

                    <Col sm={4}>
                        <Tooltip placement="topLeft" title="Search and compare schools based on zipcode or states" arrow={true}>
                            <Button
                                className='location-button'
                                style={{ marginTop: 0 }}
                                icon={<PushpinOutlined />}
                                onClick={(e) => this.toggleMoreSearch(e)}>
                                Location/Health Filters
                            </Button>
                        </Tooltip>
                    </Col>

                    <Col sm={2}>
                        {canExport(this.props) && <Button
                            className='compare-export-button export-button'
                            style={{ marginTop: 0 }}
                            icon={<ExportOutlined />}
                            onClick={(e) =>
                                this.handleExportButton(e, compareEntities)
                            }>
                            Export
                        </Button>}
                    </Col>
                </Row>
                {/*<Row>
                    <Col sm={4}>
                        <Button
                            className='compare-button'
                            icon={<CloseOutlined />}
                            onClick={(e) => this.handleClearButton(e)}>
                            Clear
                        </Button>
                    </Col>

                    <Col sm={4}>
                        {canExport(this.props) && <Button
                            className='compare-export-button export-button'
                            icon={<ExportOutlined />}
                            onClick={(e) =>
                                this.handleExportButton(e, compareEntities)
                            }>
                            Export
                        </Button>}
                    </Col>
                        </Row> */}
                {this.state.loading ? <Space style={{ marginLeft: '40%' }} size="middle">
                    <Spin size="large" />
                </Space> :
                    <>
                        <p style={{ marginTop: '10px', fontWeight: 600 }}>* Non-debt issuers will have N/A for their financial data</p>
                        <Table
                            style={{ marginTop: '10px', cursor: 'move' }}
                            className='compare-table'
                            rowKey='id'
                            pagination={false}
                            columns={columns}
                            dataSource={compareEntities ? _.map(compareEntities.items, (data) => data) : []}
                            scroll={{ x: 1300, y: 500 }}
                            rowClassName={(record, index) => {
                                const found = _.filter(
                                    highlightEntity,
                                    (obj) => {
                                        let rid = this.getEntityId(record);// record.school_id ? record.school_id : (record.cmo_id ? record.cmo_id : record.obligated_group_id);
                                        let oid = this.getEntityId(obj); // obj.school_id ? obj.school_id : (obj.cmo_id ? obj.cmo_id : obj.obligated_group_id);
                                        return (rid && oid === rid);
                                    }
                                )
                                if (found.length) {
                                    return index % 2 === 0
                                        ? 'alternate-row compare-highlight'
                                        : 'compare-highlight'
                                } else {
                                    return index % 2 === 0 ? 'alternate-row' : ''
                                }
                            }}



                            onRow={(record, rowIndex) => {

                                return {
                                    draggable: true,

                                    onDragStart: (event) => {
                                        this.setState({ dragItemIndex: rowIndex });
                                    },
                                    onDrop: (event) => {
                                        if (this.state.dragItemIndex >= 0 && rowIndex !== this.state.dragItemIndex) {
                                            // reorder items
                                            let newCompareEntities = { total: this.props.entities.compareEntities.total };
                                            newCompareEntities.items = [...this.props.entities.compareEntities.items];
                                            let temp = newCompareEntities.items[rowIndex];
                                            newCompareEntities.items[rowIndex] = newCompareEntities.items[this.state.dragItemIndex];
                                            newCompareEntities.items[this.state.dragItemIndex] = temp;
                                            setCompareEntities(newCompareEntities);
                                            this.setState({ dragItemIndex: -1 });
                                        }
                                    },
                                    onDragOver: (event) => {
                                        event.preventDefault();
                                    },
                                    onMouseEnter: (event) => { }, // mouse enter row
                                }
                            }}

                            onChange={this.handleTableChange}
                        /></>}
                {this.state.showSearchOptionDialog ? <SearchOptionsModal clearEntities={this.props.clearCompareEntities} search={this.batchSearch} setLoading={this.setLoading} close={this.toggleMoreSearch} setCompareEntities={this.setCompareEntities} /> : null}
                {this.state.showPortfolioDialog ? <PortfolioModal portfolioId={this.state.selectedPortfolioValue} setLoading={this.setLoading} createPortfolio={this.createPortfolio} close={this.closePortfolioModal} /> : null}
                {this.state.showPortfolioConfirmDialog ? <PortfolioConfirmModal portfolioId={this.state.selectedPortfolioValue} setLoading={this.setLoading} deletePortfolio={this.deletePortfolio} close={this.closePortfolioConfirmModal} /> : null}
            </>
        )
    }
}

const mapStateToProps = ({ entities }) => {
    return {
        entities,
    }
}

export default connect(mapStateToProps, {
    setCompareSearchAndRefresh,
    removeCompareEntity,
    clearCompareEntities,
    setCompareEntities,
    compareSearch,
    addAndLoadCompareEntity,
    setCompareFilters,
    setCompareFiltersAndRefresh,
    setActivePortfolio,
})(withRouter(Compare))
